<template>
  <div />
</template>

<script>

export default {
  name: 'EstImageMessageView',
  components: {
  },
  mixins: [],
  props: {},
  data() {
    return { }
  },
  computed: {},
  watch: { },
  created() { },
  mounted() {},
  methods: { }
}
</script>
