<template>
  <div class="tile is-parent">
    Test
  </div>
</template>

<script>
// import { codemirror } from '@/components/VueCodemirror'
// language js

export default {
  name: 'EstImageMessageDetail',
  components: {
    // codemirror
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    options: null
  },
  data() {
  },
  computed: {},
  watch: {},
  created() {},
  mounted() { },
  methods: { }
}
</script>

<style lang="scss">
</style>
